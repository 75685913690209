const renderMarkup = (player) => {
  const markup = `
    <div class='quality_frame'>
      <button class='quality_setting'>
        Auto
      </button>
      <ul class='quality_bitrate quality_ul'>
      </ul>
    </div>
  `;
  const qualityFrame = document.createElement("div");
  qualityFrame.classList.add("quality_frame_container");
  qualityFrame.innerHTML = markup;
  const fullscreenControl = document.querySelector(".vjs-fullscreen-control");
  fullscreenControl.parentNode.insertBefore(qualityFrame, fullscreenControl);
};

const initializeListeners = (player) => {
  const qualityBitrate$ = document.querySelector(".quality_bitrate");
  const qualitySetting$ = document.querySelector(".quality_setting");
  qualitySetting$.addEventListener("click", () => {
    qualityBitrate$.classList.toggle("is-visible");
  });

  document.querySelectorAll(".quality_bitrate li").forEach(($li) => {
    $li.addEventListener("click", () => {
      qualityBitrate$.classList.remove("is-visible");
      saveConfigToLocalStorage($li.dataset.level)
      if ($li.dataset.level === "auto") {
        player.qualityLevels().levels_.forEach((level) => {
          level.enabled = true;
        });
        qualitySetting$.innerText = "Auto";
      } else {
        player.qualityLevels().levels_.forEach((level) => {
          if (level.height === Number($li.dataset.level)) {
            level.enabled = true;
          } else {
            level.enabled = false;
          }
        });
        qualitySetting$.innerText = `${$li.dataset.level}p`;
      }
    });
  });
};

const renderLevels = (levels) => {
  const getMarkup = (level) => `<a>${level}</a>`;
  const autoLevel = document.createElement("li");
  autoLevel.dataset.level = "auto";
  autoLevel.innerHTML = getMarkup("Auto");
  let qualityLevels$ = levels.map((level) => {
    const li$ = document.createElement("li");
    li$.innerHTML = getMarkup(`${level.height}p`);
    li$.dataset.level = level.height;
    return li$;
  });
  qualityLevels$ = [...qualityLevels$, autoLevel];
  document.querySelector(".quality_bitrate").append(...qualityLevels$);
};

const initializeQualityLevels = (player) => {
  const qualityLevels = player.qualityLevels();
  const levels = qualityLevels.levels_.sort((a, b) => a - b).reverse();

  renderMarkup(player);
  renderLevels(levels);
  initializeListeners(player);
}

const initializeConfigFromLocalStorage = () => {
  try {
    const data =
      JSON.parse(window.localStorage.getItem("videojs-persist")) || {};
    window.player.persist = data;
    if (data.volume) {
      window.player.volume(data.volume)
    }
    if (data.muted) {
      window.player.muted(data.muted)
    }
    if (data.playbackRate) {
      window.player.playbackRate(data.playbackRate)
    }
  } catch (e) {
    console.log("localStorage is not supported");
  }
};

const saveConfigToLocalStorage = () => {
  try {
    const dataFromLS =
    JSON.parse(window.localStorage.getItem("videojs-persist")) || {};
    const data = {
      volume: window.player.volume(),
      muted: window.player.muted(),
      playbackRate: window.player.playbackRate(),
    }
    window.localStorage.setItem("videojs-persist", JSON.stringify(data))
  
  } catch (err) {
    console.log('unable to save to ls')
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const $video = document.getElementById("player");

  if (!$video) {
    return;
  }

  const options = {
    controlBar: {
      playToggle: true,
      currentTimeDisplay: true,
      timeDivider: true,
      durationDisplay: true,
      skipButtons: {
        backward: 5,
        forward: 5,
      },
      progressControl: true,
      volumePanel: {
        inline: false,
      },
      pictureInPictureToggle: true,
      fullscreenToggle: true,
    },
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    preload: "auto",
    fluid: true,
    responsive: true,
    controls: true,
    poster: window.data.poster_cdn_url,
    plugins: {
      hotkeys: {
        enableVolumeScroll: false,
      },
    },
  };

  const srcOptions = {
    src: window.data.cdn_url,
    type: "application/x-mpegURL",
  };
  window.player = videojs("player", options);
  window.player.src(srcOptions);

  window.player.on("loadedmetadata", () => {
    initializeQualityLevels(player);
  });

  window.player.ready(() => {
    initializeConfigFromLocalStorage();
  });

  window.player.on("volumechange", () => {
    saveConfigToLocalStorage();
  });

  window.player.on("ratechange", () => {
    saveConfigToLocalStorage();
  });
});
