import $ from "jquery";
window.jQuery = window.$ = $;
window.$ = $;
import CookiesEuBanner from "cookies-eu-banner";
window.CookiesEuBanner = CookiesEuBanner;
import "./imports/mobile_menu";
import "./imports/cookie_banner";
import "./imports/lecture";
import "./imports/comments";
import "./imports/newsletter";
import videojs from "video.js";
import "videojs-hotkeys";
//import qualitySelector from 'videojs-hls-quality-selector';
//import qualityLevels from 'videojs-contrib-quality-levels';

//if (!videojs.getPlugin('qualityLevels')) {
//  console.log('!!!', videojs.getPlugin('qualityLevels'))
//  videojs.registerPlugin('qualityLevels', qualityLevels);
//}
//videojs.registerPlugin('hlsQualitySelector', qualitySelector);
window.videojs = videojs;

import "./imports/player";
