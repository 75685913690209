import React, { useState } from "react";
import ReactDOM from "react-dom";

const NewsletterForm = ({
  usersCount,
  newsletterSubscribersCount,
  newsletterUrl,
}) => {
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");

  const send = (type) => {
    fetch(newsletterUrl, {
      method: "POST",
      body: JSON.stringify({ subject, text, type }),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("success", result);
      });
  };

  return (
    <React.Fragment>
      <h1 className="text-4xl text-center mb-10">Newsletter</h1>
      <div className="text-2xl text-center mb-10">
        users {usersCount} - newsletter subscribers {newsletterSubscribersCount}
      </div>
      <div>
        <label className="block text-xs font-semibold text-gray-600 uppercase">
          Subject
        </label>
        <input
          type="text"
          value={subject}
          className="block w-full py-3 px-1 mt-2 text-gray-800 appearance-none border-2 border-gray-200 focus:outline-none focus:border-gray-300"
          onChange={(e) => setSubject(e.target.value)}
        />
        <label className="block text-xs font-semibold text-gray-600 uppercase mt-2">
          Text
        </label>
        <textarea
          className="block w-full py-3 px-1 mt-2 text-gray-800 appearance-none border-2 border-gray-200 focus:outline-none focus:border-gray-300"
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <button
          className="w-full py-3 mt-7 bg-el-teal rounded-sm font-medium text-white uppercase focus:outline-none hover:bg-el-teal-dark hover:shadow-none cursor-pointer"
          onClick={() => send("test")}
        >
          Send test email
        </button>
        <button
          className="w-full py-3 mt-7 bg-red-600 rounded-sm font-medium text-white uppercase focus:outline-none enabled:hover:bg-red-800 enabled:hover:shadow-none cursor-pointer block disabled:opacity-75 disabled:cursor-not-allowed"
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you want to send the email to users and subscribers?",
              )
            ) {
              send("all");
            }
          }}
        >
          Send email to users and subscribers
        </button>
      </div>
    </React.Fragment>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const $newsletterForm = document.getElementById("newsletter-form");
  if (!$newsletterForm) {
    return;
  }

  ReactDOM.render(
    <NewsletterForm
      usersCount={window.data.users_count}
      newsletterSubscribersCount={window.data.newsletter_subscribers_count}
      newsletterUrl={window.data.newsletter_url}
    />,
    $newsletterForm,
  );
});
