// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

// const canManageComment = () => {
//   const fiveMinutes = 300000;
//   const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;

//   if (timePassed) {
//     return false;
//   }

//   if (!_.isEmpty(comment.comments)) {
//     return false;
//   }
//   return Boolean(currentUser && currentUser.id === comment.user.id);
// };

const getHumanDate = (time) => {
  const templates = {
    prefix: "",
    suffix: " ago",
    seconds: "less than minute",
    minute: "near minute",
    minutes: "%d minutes",
    hour: "near an hour",
    hours: "%d hours",
    day: "1 day",
    days: "%d days",
    month: "1 month",
    months: "%d months",
    year: "1 year",
    years: "%d years",
  };

  const template = (t, n) =>
    templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));

  if (!time) {
    return null;
  }

  time = new Date(time);

  const now = new Date();
  const seconds = ((now.getTime() - time) * 0.001) >> 0; // eslint-disable-line no-bitwise
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const years = days / 365;

  return `${templates.prefix}${
    (seconds < 45 && template("seconds", seconds)) ||
    (seconds < 90 && template("minute", 1)) ||
    (minutes < 45 && template("minutes", minutes)) ||
    (minutes < 90 && template("hour", 1)) ||
    (hours < 24 && template("hours", hours)) ||
    (hours < 42 && template("day", 1)) ||
    (days < 30 && template("days", days)) ||
    (days < 45 && template("month", 1)) ||
    (days < 365 && template("months", days / 30)) ||
    (years < 1.5 && template("year", 1)) ||
    template("years", years)
  }${templates.suffix}`;
};

const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = null,
  currentUserId,
  userIconUrl,
}) => {
  const createdAt = getHumanDate(comment.created_at);
  const isEditing =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "editing";
  const isReplying =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "replying";
  const fiveMinutes = 300000;
  const timePassed = new Date() - new Date(comment.created_at) > fiveMinutes;
  const canDelete =
    currentUserId === comment.user_id && replies.length === 0 && !timePassed;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.user_id && !timePassed;
  const replyId = parentId ? parentId : comment.id;
  return (
    <div key={comment.id} className="flex mb-7">
      <div className="mr-3">
        <img src={userIconUrl} className="rounded-full" />
      </div>
      <div className="w-full">
        <div className="flex flex-col md:flex-row">
          <div className="mr-2 text-xl text-blue-500">
            {comment.user?.name ?? "Unknown"}
          </div>
          <div>{createdAt}</div>
        </div>
        {!isEditing && (
          <div className="text-lg whitespace-pre-wrap break-all">
            {comment.body}
          </div>
        )}
        {isEditing && (
          <CommentForm
            submitLabel="Update"
            hasCancelButton
            initialText={comment.body}
            handleSubmit={(text) => updateComment(text, comment.id)}
            handleCancel={() => {
              setActiveComment(null);
            }}
          />
        )}
        <div className="flex text-xs text-color-gray-500 cursor-pointer mt-2">
          {canReply && (
            <div
              className="mr-2 hover:underline"
              onClick={() =>
                setActiveComment({ id: comment.id, type: "replying" })
              }
            >
              Reply
            </div>
          )}
          {canEdit && (
            <div
              className="mr-2 hover:underline"
              onClick={() =>
                setActiveComment({ id: comment.id, type: "editing" })
              }
            >
              Edit
            </div>
          )}
          {canDelete && (
            <div
              className="mr-2 hover:underline"
              onClick={() => deleteComment(comment.id)}
            >
              Delete
            </div>
          )}
        </div>
        {isReplying && (
          <CommentForm
            submitLabel="Reply"
            handleSubmit={(text) => addComment(text, replyId)}
          />
        )}
        {replies.length > 0 && (
          <div className="mt-5">
            {replies.map((reply) => (
              <Comment
                comment={reply}
                key={reply.id}
                setActiveComment={setActiveComment}
                activeComment={activeComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                addComment={addComment}
                parentId={comment.id}
                replies={[]}
                currentUserId={currentUserId}
                userIconUrl={userIconUrl}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
}) => {
  const [text, setText] = useState(initialText);
  const isTextareaDisabled = text.length === 0;
  const hoverClass = isTextareaDisabled ? "" : "hover:bg-blue-600";
  const opacityClass = isTextareaDisabled ? "opacity-70" : "";
  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text);
    setText("");
  };
  return (
    <form onSubmit={onSubmit}>
      <textarea
        className="border border-gray-500 w-full h-20 my-2"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button
        className={`bg-blue-500 rounded-md text-white px-4 py-2 mr-2 ${hoverClass} ${opacityClass}`}
        disabled={isTextareaDisabled}
      >
        {submitLabel}
      </button>
      {hasCancelButton && (
        <button
          type="button"
          className="bg-blue-500 rounded-md text-white px-4 py-2 hover:bg-blue-600"
          onClick={handleCancel}
        >
          Cancel
        </button>
      )}
    </form>
  );
};

const Comments = ({ commentsUrl, currentUserId, userIconUrl }) => {
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const rootComments = backendComments.filter(
    (backendComment) => backendComment.parent_id === null,
  );
  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parent_id === commentId)
      .sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
  const addComment = (text, parentId) => {
    fetch(commentsUrl, {
      method: "POST",
      body: JSON.stringify({ comment: { body: text, parent_id: parentId } }),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((comment) => {
        setBackendComments([comment, ...backendComments]);
        setActiveComment(null);
      });
  };
  const updateComment = (text, commentId) => {
    const url = `${commentsUrl}/${commentId}`;
    fetch(url, {
      method: "PATCH",
      body: JSON.stringify({ comment: { body: text } }),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((comment) => {
        const updatedBackendComments = backendComments.map((backendComment) => {
          if (backendComment.id === commentId) {
            return comment;
          }
          return backendComment;
        });
        setBackendComments(updatedBackendComments);
        setActiveComment(null);
      });
  };
  const deleteComment = (commentId) => {
    if (confirm("Are you sure you want to remove comment?")) {
      const url = `${commentsUrl}/${commentId}`;
      fetch(url, {
        method: "DELETE",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        const updatedBackendComments = backendComments.filter(
          (backendComment) => backendComment.id !== commentId,
        );
        setBackendComments(updatedBackendComments);
      });
    }
  };

  useEffect(() => {
    fetch(commentsUrl)
      .then((res) => res.json())
      .then((data) => setBackendComments(data));
  }, []);

  return (
    <div className="mt-5">
      <h3 className="text-3xl mb-5">Comments</h3>
      {currentUserId && (
        <React.Fragment>
          <div className="text-xl">Write comment</div>
          <CommentForm submitLabel="Write" handleSubmit={addComment} />
        </React.Fragment>
      )}
      {!currentUserId && (
        <div className="bg-green-100 p-4">
          Only registered users can leave comments. Please log in.
        </div>
      )}
      <div className="mt-10">
        {rootComments.map((rootComment) => (
          <Comment
            key={rootComment.id}
            comment={rootComment}
            replies={getReplies(rootComment.id)}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            addComment={addComment}
            deleteComment={deleteComment}
            updateComment={updateComment}
            currentUserId={currentUserId}
            userIconUrl={userIconUrl}
          />
        ))}
      </div>
    </div>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const $comments = document.getElementById("comments");
  if (!$comments) {
    return;
  }

  ReactDOM.render(
    <Comments
      commentsUrl={window.data.comments_api_url}
      currentUserId={window.data.current_user_id}
      userIconUrl={window.data.user_icon_url}
    />,
    $comments,
  );
});
